
import {defineComponent, PropType} from 'vue';
import {IToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {getErrorMsg, swalNotification} from '@/core/helpers/utils';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { usePagination } from '@/core/hooks/use-pagination';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ToaXeService from '@/core/services/ToaXe.service';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';

export default defineComponent({
	name: 'danh-sach-toa-xe-table',

	components: { ButtonCustom, ConfirmationDialog },

	emits: ['delete-toa-xe-success'],

	setup() {
		const { push } = useRouterCustom();

		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		const { indexCal } = usePagination();

		return { push, ButtonsType, ButtonTypeColors, indexCal };
	},

	props: {
		curPage: {
			type: Number,
			default: 0,
		},
		items: {
			type: Array as PropType<Array<IToaXeResponseBase>>
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		perPage: {
			type: Number,
			default: 0,
		},
    showDmToaXe: {
      type: Boolean,
      default: false,
    }
	},

	data: () => ({
		deletingToaXe: null as IToaXeResponseBase | null,
		isDeletingModalDisplay: false,
	}),

	methods: {
		onDeleteButtonClicked(id) {
			const toaXe = this.items?.find(item => item.id === id);
			this.isDeletingModalDisplay = true;
			if (toaXe) {
				this.deletingToaXe = toaXe;
			}
		},

		async confirmDelete() {
			this.isDeletingModalDisplay = false;
			try {
				if (this.deletingToaXe) await ToaXeService.delete(this.deletingToaXe?.id);
				await swalNotification(
					'Xóa thành công',
					'success'
				);
				this.$emit('delete-toa-xe-success');
			} catch (error) {
				await swalNotification(
					getErrorMsg(error, 'Có lỗi xảy ra, không thể xóa toa xe này'),
					'error'
				);
			}
		}
	}
})
